<template>
  <CustomModal ref="modal" class="edit-elements-business-modal">
    <h2 style="text-align: center">Modifier les éléments du devis</h2>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <div class="header-container">
          <CustomTextField class="title" :content=inputs.title />
          <CustomButton class="header-footer-button" @click="openEditBusinessModal" :params=buttons.editHeaderAndFooter style="width: 200px; margin-top: 17px;margin-left: auto;margin-right: 0;" />
        </div>
        <div class="element-container element-header-container">
          <div class="element"><p>Description</p></div>
          <div class="durationQuantity"><p>Temps/QTÉ</p></div>
          <div class="unitPrice"><p>Prix unitaire</p></div>
          <div class="discountedRate"><p>Remise (%)</p></div>
          <div class="tva"><p>TVA (%)</p></div>
          <div class="amount"><p>Prix HT</p></div>
        </div>
        <div class="elements-container" ref="elementsContainer">
          <div
              v-for="(el, index) in inputs.BusinessElements"
              :key="el"
              class="element-pre-container"
              :class="{ 'drag-over': dragOverIndex === index }"
              draggable="true"
              @dragstart="onDragStart($event, index)"
              @dragover="onDragOver($event, index)"
              @drop="onDrop($event, index)"
              @dragend="onDragEnd"
          >
            <div v-if="el.type === 'element'" class="element-container">
              <div class="element"><CustomTextField :content=el.text /></div>
              <div class="durationQuantity"><CustomTextField :content=el.durationQuantity /></div>
              <div class="unitPrice"><CustomTextField :content=el.unitPrice /></div>
              <div class="discountedRate"><CustomTextField :content=el.discountedRate /></div>
              <div class="tva"><CustomTextField :content=el.tva /></div>
              <div class="amount"><p :class="{ 'text-deactivated' : isInvoice}">{{el.amount.value}}</p><p class="currency" :class="{ 'text-deactivated' : isInvoice}">€</p></div>
              <div v-if="!isInvoice" class="minus-button-container"><button type="button" class="add-element button-icon" @click="removeElement(index)"><span class="fas fa-minus"></span></button></div>
            </div>
            <div v-if="el.type === 'title'" class="element-container">
              <div class="title"><CustomTextField :content=el.text /></div>
              <div v-if="!isInvoice" class="minus-button-container"><button type="button" class="add-element button-icon" @click="removeElement(index)"><span class="fas fa-minus"></span></button></div>
            </div>
          </div>
        </div>
        <div v-if="!isInvoice" class="plus-button-container">
          <button type="button" @click="isChoiceDisplayed=!isChoiceDisplayed" class="add-element button-icon"><span class="fas fa-plus"></span></button>
          <div class="choice" :class="{ 'choice-displayed': isChoiceDisplayed }">
            <button type="button" @click="addElement('element')">Elément</button>
            <button type="button" @click="addElement('title')" style="margin-top: 5px">Titre</button>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div class="total-amount" :class="{ 'text-deactivated' : isInvoice}">
          <p class="text">HT</p>
          <div>
            <p class="amount">{{ htAmount }}</p>
            <p class="currency">€</p>
          </div>
        </div>
        <div class="total-amount" :class="{ 'text-deactivated' : isInvoice}">
          <p class="text">TVA</p>
          <div>
            <p class="amount">{{ tvaAmount }}</p>
            <p class="currency">€</p>
          </div>
        </div>
        <div class="total-amount" :class="{ 'text-deactivated' : isInvoice}">
          <p class="text">TTC</p>
          <div>
            <p class="amount">{{ ttcAmount }}</p>
            <p class="currency">€</p>
          </div>
        </div>
        <CustomButton type="submit" @click="submitForm" :params=buttons.submit style="width: 200px;margin-top: 30px" />
      </CustomForm>
    </div>
  </CustomModal>
  <EditBusinessModal ref="editBusinessModal" :callback=closeEditBusinessModal />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faPlus, faUpLong, faCheck, faExclamation, faMinus } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import {ClientController} from "@/controllers/business/ClientController";
import {NotificationService} from "@vues/v-common";
import {BusinessController} from "@/controllers/business/BusinessController";
import EditBusinessModal from "@/components/modals/EditBusinessModal.vue";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import {BusinessGroupParamsController} from "@/controllers/business/BusinessGroupParamsController";
import {BUSINESS_TYPE_ENUM} from "@/models/businessTypeEnum";
library.add(faXmark);
library.add(faPlus);
library.add(faCheck);
library.add(faUpLong);
library.add(faMinus);
library.add(faExclamation);

  export default {
    name: 'EditElementsBusinessModal',
    computed: {
      isInvoice() {
        return this.business ? this.business.type === BUSINESS_TYPE_ENUM.INVOICE.value : true;
      }
    },
      props: ['callback'],
      components: {CustomModal, CustomForm, EditBusinessModal, CustomTextField, CustomButton},
      data() {
          return {
            business: undefined,
            businessId: undefined,
            defaultUnitPrice: undefined,
            htAmount: undefined,
            tvaAmount: undefined,
            ttcAmount: undefined,
            inputs: {
              title: {label: 'Titre', value: '', type: 'text', required: true},
              BusinessElements: [],
            },
            buttons:{
              submit: {text: "Valider"},
              editHeaderAndFooter: {text: "Modifier les autres informations"}
            },
            isTerminateButtonDisplayed: false,
            dragStartIndex: null,
            dragOverIndex: null,
            isChoiceDisplayed: false,
          };
      },
      watch:{
          "inputs.BusinessElements": {
            deep: true,
            handler(businessElements) {
              for(let i in businessElements){
                const discount = (businessElements[i].discountedRate.value ? (100 - businessElements[i].discountedRate.value) / 100 : 1);
                const amount = (businessElements[i].durationQuantity.value * businessElements[i].unitPrice.value * discount);
                businessElements[i].amount.value = amount ? amount.toFixed(2) : 0;
              }
              this.htAmount = Object.values(this.inputs.BusinessElements).reduce((total, el) => total + (Number(el.amount.value) ? Number(el.amount.value) : 0), 0);
              this.tvaAmount = Object.values(this.inputs.BusinessElements).reduce((total, el) => total + (Number(el.tva.value) && Number(el.amount.value) ? Number(el.tva.value) / 100 * Number(el.amount.value) : 0), 0);
              this.ttcAmount = this.htAmount + this.tvaAmount;
            }
          },
      },
      methods: {
        openModal(business) {
          this.$refs.modal.openModal();
          this.businessId = business.id;
          this.business = business;
          this.buttons.submit.disabled = false;
          this.inputs.title.value = business.title;

          this.inputs.BusinessElements = [];
          //const elements = JSON.parse(business.elements);
          if(business.elements && business.elements.length > 0) {
            for (let i=0; business.elements.length > i ;i++) {
              this.inputs.BusinessElements.push({
                type: business.elements[i].type,
                text: {value: business.elements[i].text, type: 'text'},
                durationQuantity: {value: business.elements[i].durationQuantity, type: 'number', step : 0.25},
                unitPrice: {value: business.elements[i].unitPrice, type: 'number', step : 0.25},
                discountedRate: {value: business.elements[i].discountedRate, type: 'number'},
                tva: {value: business.elements[i].tva, type: 'number'},
                amount: {value: business.elements[i].amount, type: 'number', readOnly: true}
              });
            }
          }else{
            this.addElement("title");
          }
          this.setReadOnlyForInvoice(business);
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        closeEditBusinessModal(closeEditElementsBusinessModal){
          if(closeEditElementsBusinessModal){
            this.terminate();
          }else{
            BusinessController.get(this.businessId).then(resp => this.openModal(resp));
          }
        },
        openEditBusinessModal(){
          this.$refs.editBusinessModal.openModal(this.business);
        },
        removeElement(index){
          this.inputs.BusinessElements.splice(index, 1);
        },
        setReadOnlyForInvoice(business){
          const isInvoice = business.type === BUSINESS_TYPE_ENUM.INVOICE.value;
          this.inputs.title.readOnly = isInvoice;
          for (let i=0; this.inputs.BusinessElements.length > i ;i++) {
            this.inputs.BusinessElements[i].text.readOnly = isInvoice;
            this.inputs.BusinessElements[i].durationQuantity.readOnly = isInvoice;
            this.inputs.BusinessElements[i].unitPrice.readOnly = isInvoice;
            this.inputs.BusinessElements[i].discountedRate.readOnly = isInvoice;
            this.inputs.BusinessElements[i].tva.readOnly = isInvoice;
            this.inputs.BusinessElements[i].amount.readOnly = isInvoice;
          }
        },
        async fillDefaultFileds(){
          const resp = await BusinessGroupParamsController.get(localStorage.groupId);
          if(!resp.errors){
            this.defaultUnitPrice = resp.defaultUnitPrice;
          }
        },
        addElement(type){
          if(!this.defaultUnitPrice){
            this.fillDefaultFileds();
          }
          this.inputs.BusinessElements.push({
            type: type,
            text: {value: '', type: 'text'},
            durationQuantity: {value: '', type: 'number', step : 0.25},
            unitPrice: {value: this.defaultUnitPrice, type: 'number', step : 0.25},
            discountedRate: {value: '', type: 'number'},
            tva: {value: '', type: 'number'},
            amount: {value: '', type: 'number', readOnly: true}
          });
          // Scroll to bottom
          this.$nextTick(() => {
            const container = this.$refs.elementsContainer;
            container.scrollTop = container.scrollHeight;
          });
        },
        async fillClientSelect(clientId){
          // Get the table data
          this.inputs.client.selectValues = [];
          const resp = await ClientController.getAll();
          if(!resp.errors){
            for(let i=0; i < resp.length ;i++){
              this.inputs.client.selectValues.push({label:resp[i].name, value: resp[i]._id});
            }
          }
          this.inputs.client.value = clientId;
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => BusinessController.patch(this.businessId,{
            title: this.inputs.title.value,
            // eslint-disable-next-line no-unused-vars
            elements: Object.entries(this.inputs.BusinessElements).map(([key, value]) => ({
              type: value.type,
              text: value.text.value,
              durationQuantity: value.durationQuantity.value,
              unitPrice: value.unitPrice.value,
              discountedRate: value.discountedRate.value,
              tva: value.tva.value,
              amount: value.amount.value
            })),
            tvaAmount: this.tvaAmount,
            htAmount: this.htAmount,
            ttcAmount: this.ttcAmount,
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Contrat modifié");
            this.terminate();
          }
        },
        onDragStart(event, index) {
          this.dragStartIndex = index;
          event.dataTransfer.effectAllowed = 'move';
        },
        onDragOver(event, index) {
          event.preventDefault();
          this.dragOverIndex = index;
          event.dataTransfer.dropEffect = 'move';
        },
        onDrop(event, index) {
          event.preventDefault();
          const dragStartIndex = this.dragStartIndex;
          this.dragStartIndex = null;
          this.dragOverIndex = null;

          if (dragStartIndex !== index) {
            const movedElement = this.inputs.BusinessElements.splice(dragStartIndex, 1)[0];
            this.inputs.BusinessElements.splice(index, 0, movedElement);
          }
        },
        onDragEnd() {
          this.dragStartIndex = null;
          this.dragOverIndex = null;
        }
      }
  }

</script>


<style lang="stylus">
  .edit-elements-business-modal .modal
    width 80% !important
</style>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .header-container
      display flex
      margin-bottom 50px
      .title
        margin-right 1em
        max-width 500px !important
      .header-footer-button
        margin-right 68px
        margin-left auto

    .elements-container
      max-height 300px
      overflow-y: scroll
      .element-pre-container
        width 100%

    .element-header-container
      margin-bottom 1em
      padding-right 38px
      div
        p
          margin 0
          font-weight bold
          font-size 14px

    .element-container
      display flex
      .title
        width 100%
        :deep(input.input)
          font-weight 600 !important
      .element
        width 100%
      .durationQuantity
        width 200px
      .unitPrice
        width 200px
      .discountedRate
        width 200px
      .tva
        width 150px
      .amount
        display flex
        align-items center
        justify-content space-between
        width 150px
        p
          font-size 0.9em
          margin 0
          &.text-deactivated
            color #a8a8a8
          &.currency
            padding-right 5px
      .minus-button-container
        display flex
        align-items center
        button
          width 20px
          height 20px
          margin-right 5px
          svg
            margin-left -4px

    .total-amount
      padding-right 45px
      width 130px
      display flex
      margin-left auto
      justify-content space-between
      &.text-deactivated
        padding-right 20px
        p
          color #a8a8a8
      p
        margin 0
        margin-bottom 5px
      div
        width 85px
        display flex
        justify-content space-between
      .text
        font-weight bold

    .separator
      margin-top 15px
      height 2px
      background-color THEME_COLOR_PRIMARY_FADDED
      opacity 0.6

    .plus-button-container
      display flex
      justify-content flex-end
      margin-top 10px
      margin-bottom 10px
      .choice
        display none
        flex-direction column
        position absolute
        right 60px
        padding 5px
        border-radius 6px
        background-color #b9cacf6e
        button
          border none
          cursor pointer
          border-radius 3px
          color THEME_COLOR_PRIMARY_OPPOSITE
          background-color THEME_COLOR_PRIMARY
      .add-element
        margin-top 15px
        margin-left auto
        width 30px
        height 30px

    .choice-displayed
      display flex !important

    .element-container :deep(input.input)
      padding 0
      text-indent: 0;
      height 25px
      border 2px solid rgba(255, 255, 255, 0);
      font-weight 400 !important
      &:hover
        border 2px solid THEME_COLOR_PRIMARY;

    .element-pre-container.drag-over
      border-top 2px dashed #000 /* Modify this to match your desired style */
</style>
