<template>
    <custom-nav class="custom-nav" logo_img="../../img/logo_nav.png" img_route="/" :link_routes=link_routes :user_routes=user_routes :connected="isConnected" />
</template>

<script>
    import {CustomNav} from "@vues/v-common";
    import {RightService} from "@/services/RightService";
    export default {
        name: 'ConfiguratedNav',
        components: {
          CustomNav
        },
        data: function() {
            return {
                link_routes: [],
                user_routes: [],
                isConnected: localStorage && localStorage.isConnected === "true"
            };
        },
        mounted(){
          // This event is call for rerender the navbar when the connection state change
          window.addEventListener('isConnectedChange', (event) => {
            this.isConnected = event.detail.isConnected;
            this.setRoutes();
          });
          this.setRoutes();
        },
        methods: {
          setRoutes() {
            this.user_routes = [];
            this.link_routes = [];
            if (localStorage && localStorage.isConnected === "true") {
              this.user_routes.push({name: "Paramètre du compte", route: "/user-parameters"});
              this.user_routes.push({name: "Paramètre du groupe", route: "/group-parameters"});
              this.user_routes.push({name: "Déconnexion", route: "/logout"});

              if(localStorage.options && localStorage.options.includes("business")) {
                this.link_routes.push({name: "Clients", route: "/clients"});
                this.link_routes.push({name: "Contrats", route: "/businesses"});
              }

              if(localStorage.options && localStorage.options.includes("insurance")) {
                this.link_routes.push({name: "Clients d'assurance", route: "/insurance-clients"});
                this.link_routes.push({name: "Contrats d'assurance", route: "/insurance-businesses"});
                this.link_routes.push({name: "Recouvrement", route: "/insurance-recovery"});
              }

              if (RightService.isAtLeastModerator()) {
                this.link_routes.push({name: "Utilisateurs", route: "/users"});
              }
            } else {
              this.user_routes.push({name: "Créer mon compte", route: "/register"});
              this.user_routes.push({name: "Connection", route: "/login"});
            }
          }
        }
    }
</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
</style>

<style lang="stylus">
  .custom-nav
    --THEME_COLOR_NAV_HEADER #17233f !important
</style>
