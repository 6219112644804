<template>
    <div class="container-screen">
        <div class="screen">
          <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
              <CustomTextField :content=inputs.email />
              <CustomTextField :content=inputs.password style="padding-top: 20px;"/>

              <div class="reset-password">
                <router-link :to="'/reset-password'">Mot de passe oublié</router-link>
              </div>

              <div  class="bad message_field"><span v-if="message">{{message}}</span></div>

              <CustomButton @click="submitForm" :params=buttons.submit />

              <div class="separator">
                <span></span>
                <label>Pas de compte ?</label>
                <span></span>
              </div>

              <router-link :to="'/register'" style="width: 100%">
                  <CustomButton :params=buttons.signIn />
              </router-link>
          </CustomForm>
        </div>
    </div>
</template>

<script>
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faUser, faLock, faChevronRight, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
 import router from '../../router';
 import { JWTService } from "../../services/JWTService";
 import CustomButton from "./customs/customButton/CustomButton.vue";
 import CustomTextField from "./customs/customTextField/CustomTextField.vue";
 import {UserController} from "@/controllers/user/UserController";
 import CustomForm from "@/components/forms/CustomForm.vue";

 library.add(faUser);
 library.add(faChevronRight);
 library.add(faLock);
 library.add(faCheck);
 library.add(faXmark);

  export default {
      name: 'LoginForm',
      components: {
        CustomForm,
          CustomButton,
          CustomTextField,
      },
      data() {
          return {
              inputs: {
                  email: {label: 'Email', value: '', type: 'email'},
                  password: {label: 'Mot de passe', value: '', type: 'password'},
              },
              buttons: {
                signIn: {text: "Créer mon compte"},
                submit: {text: "Connection"},
              },
              message: '',
          };
      },
      methods: {
        submitCallBack(e){
          this.message = e.message;
          if(!e.errors){

              // Clear local storage before to avoid an eventual previous localstorage collision
              localStorage.clear();

              localStorage.token = e.token;
              const jwtService = new JWTService();
              const token = jwtService.verifyJWT(localStorage.token);
              Object.assign(localStorage, token);

              if(token.isConditionsMissing){
                router.push("/accept-cgu");
              }else if(token.isNeedToChangeGroup) {
                router.push("/choose-group");
              }else{
                localStorage.isConnected = true;
                localStorage.encryptionKey = this.inputs.password.value;

                // Send a notification to the navbar for a rerender it
                window.dispatchEvent(new CustomEvent('isConnectedChange', {detail: {isConnected: true}}));
                // TODO faire ça via paramettre car ça peu changer et ça sera placé en common
                router.push("/");
              }
            }else{
              if(e.errors && e.errors[0].code === "EMAIL_NOT_VERIFIED"){
                router.push("/confirm-mail");
              }
            }
          },
          async submitForm() {
            this.submitCallBack(await this.$refs.form.submitForm(() => UserController.login(
                this.inputs.email.value,
                this.inputs.password.value
            )));
          }
      }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl';
  @import 'responsive-form.styl'
</style>
