<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Modifier un contrat provisoir</h2>
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <div class="form-sub-container">
          <div>
            <CustomTextField :content=inputs.attestationNumber />
            <CustomTextField :content=inputs.quittance />
            <CustomTextField :content=inputs.amendmentEffectiveDate />
            <CustomTextField :content=inputs.expirationDate />
            <CustomTextField :content=inputs.primeTTC />
          </div>
          <div>
            <CustomTextField :content=inputs.subscriber />
            <CustomTextField :content=inputs.vehicleRegistration />
            <CustomTextField :content=inputs.vehicleBrand />
            <CustomTextField :content=inputs.usage />
          </div>
        </div>
        <CustomButton @click="submitForm" :params=buttons.submit class="submit" />
      </CustomForm>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faPlus, faUpLong, faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import {NotificationService} from "@vues/v-common";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import {InsuranceBusinessController} from "@/controllers/insurance/InsuranceBusinessController";
import {decrypteData, encrypteData, convertISOToDate} from "@/utils";
library.add(faXmark);
library.add(faPlus);
library.add(faCheck);
library.add(faUpLong);
library.add(faExclamation);

  export default {
      name: 'EditInsuranceBusinessModal',
      props: ['callback'],
      components: {CustomModal, CustomForm, CustomTextField, CustomButton},
      data() {
          return {
            businessId: undefined,
            documentName: "test",
            inputs: {
              attestationNumber: {label: 'Attestation', value: '', type: 'text', required: true},
              usage: {label: 'Usage', value: '', type: 'text', required: true},
              amendmentEffectiveDate: {label: 'Date d\'effet', value: '', type: 'date', required: true},
              expirationDate: {label: 'Date d\'expiration', value: '', type: 'date', required: true},
              subscriber: {label: 'Souscripteur', value: '', type: 'text', required: true},
              vehicleBrand: {label: 'Marque', value: '', type: 'text', required: true},
              vehicleRegistration: {label: 'Immatriculation', value: '', type: 'text', required: true},
              quittance: {label: 'Quittance', value: '', type: 'text', required: true},
              primeTTC: {label: 'Prime TTC', value: '', type: 'number', required: true},
            },
            buttons:{
              submit: {text: "Modifier", disabled: true}
            },
            isTerminateButtonDisplayed: false,
          };
      },
      methods: {
        openModal(business) {
          this.$refs.modal.openModal();
          this.buttons.submit.disabled = true;
          this.businessId = business.id;
          this.inputs.attestationNumber.value = decrypteData(business.attestationNumber);
          this.inputs.usage.value = decrypteData(business.usage);
          this.inputs.amendmentEffectiveDate.value = convertISOToDate(business.amendmentEffectiveDate);
          this.inputs.expirationDate.value = convertISOToDate(business.expirationDate);
          this.inputs.subscriber.value = decrypteData(business.subscriber);
          this.inputs.vehicleBrand.value = decrypteData(business.vehicleBrand);
          this.inputs.vehicleRegistration.value = decrypteData(business.vehicleRegistration);
          this.inputs.quittance.value = decrypteData(business.quittance);
          this.inputs.primeTTC.value = business.primeTTC;
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        async submitForm() {
          console.log(this.inputs.amendmentEffectiveDate.value);
          const res = await this.$refs.form.submitForm(() => InsuranceBusinessController.patch(this.businessId,{
            attestationNumber: encrypteData(this.inputs.attestationNumber.value),
            usage: encrypteData(this.inputs.usage.value),
            amendmentEffectiveDate: this.inputs.amendmentEffectiveDate.value,
            expirationDate: this.inputs.expirationDate.value,
            subscriber: encrypteData(this.inputs.subscriber.value),
            vehicleBrand: encrypteData(this.inputs.vehicleBrand.value),
            vehicleRegistration: encrypteData(this.inputs.vehicleRegistration.value),
            quittance: encrypteData(this.inputs.quittance.value),
            primeTTC: this.inputs.primeTTC.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Contrat modifié");
            this.terminate();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .form-sub-container
      display flex
      flex-direction row
      padding-left 20px
      padding-right 20px
      width calc(100% - 40px)
      gap 30px

    .submit
      width 200px
      margin-top 20px
      margin-right calc(50% - (200px / 2))
      margin-left auto
</style>
