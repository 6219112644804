<template>
  <CustomTable ref="table" :callback=reloadCallback :filters=filtersInputs>
    <template v-slot:header-options>
      <button @click="$refs.addBusinessModal.openModal()" class="button-icon"><span class="fas fa-plus"></span></button>
    </template>
    <template v-slot:table>
      <thead>
        <tr>
          <!--<th>Création</th>-->
          <custom-header :ct=ct :data=data field="number" @updateData=updateData class="hide-for-mobile">N°</custom-header>
          <custom-header :ct=ct :data=data field="title" @updateData=updateData class="hide-for-mobile">Titre</custom-header>
          <custom-header :ct=ct :data=data field="type" @updateData=updateData class="hide-for-mobile">Type</custom-header>
          <custom-header :ct=ct :data=data field="status" @updateData=updateData class="hide-for-mobile">Status</custom-header>
          <custom-header :ct=ct :data=data field="clientName" @updateData=updateData>Client</custom-header>
          <custom-header :ct=ct :data=data field="ttcAmount" @updateData=updateData class="hide-for-mobile">Montant</custom-header>
          <custom-header :ct=ct :data=data field="remaining" @updateData=updateData>Solde</custom-header>
          <custom-header :ct=ct :data=data field="actions" @updateData=updateData>Actions</custom-header>
        </tr>
      </thead>
      <tbody v-if="data && data.length > 0">
        <tr v-for="(business, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index">
          <!--<td class="only-desktop-table-cell" style="max-width: 68px">{{ business.createdAt ? (new Date(business.createdAt)).toLocaleDateString("en-GB", formattedDateTime) : '-'}}</td>-->
          <custom-body :ct=ct field="number" class="hide-for-mobile">{{ business.number ? business.number : ''}}</custom-body>
          <custom-body :ct=ct field="title" class="hide-for-mobile">{{ business.title ? business.title : ''}}</custom-body>
          <custom-body :ct=ct field="type" class="hide-for-mobile">{{ business.type ? BUSINESS_TYPE_ENUM[business.type].label : '' }}</custom-body>
          <custom-body :ct=ct field="status" class="hide-for-mobile">{{ business.status ? BUSINESS_STATUS_ENUM[business.status].label : '' }}</custom-body>
          <custom-body :ct=ct field="clientName">{{ business.clientName ? business.clientName : ''}}</custom-body>
          <custom-body :ct=ct field="ttcAmount" class="hide-for-mobile">{{ business.ttcAmount ? business.ttcAmount.toLocaleString('fr-FR') + ' €' : ''}}</custom-body>
          <custom-body :ct=ct field="remaining">{{business.remaining ? business.remaining.toLocaleString('fr-FR')  + ' €' : ''}}</custom-body>
          <custom-body :ct=ct field="actions">
            <button class="button-icon-custom hide-for-mobile" @click="$refs.editElementsBusinessModal.openModal(business)"><span class="fas fa-file-pen"></span></button>
            <button class="button-icon-custom" @click="downloadPdf(business)"><span class="fas fa-file-pdf"></span></button>
            <button class="button-icon-custom" @click="$refs.addCollectionModal.openModal(business.id)"><span class="fas fa-money-bill-transfer"></span></button>
          </custom-body>
        </tr>
      </tbody>
      <tbody v-else><tr class="empty-row"><td colspan="8">Aucun contrat enregitré</td></tr></tbody>
    </template>
  </CustomTable>
  <EditElementsBusinessModal ref="editElementsBusinessModal" :callback=loadData />
  <AddCollectionModal ref="addCollectionModal" :callback=loadData />
  <AddBusinessModal ref="addBusinessModal" :callback=loadData />
  <BusinessPDF ref="businessPDF" />
</template>

<script>
import AddCollectionModal from "@/components/modals/AddCollectionModal.vue";
import AddBusinessModal from "@/components/modals/AddBusinessModal.vue";
import {BusinessController} from "@/controllers/business/BusinessController";
import EditElementsBusinessModal from "@/components/modals/EditElementsBusinessModal.vue";
import {BUSINESS_STATUS_ENUM} from "@/models/businessStatusEnum";
import CustomTable from "@/components/CustomTable.vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBillTransfer, faList, faChevronLeft, faChevronRight, faPlus, faFilePen, faFileLines, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {BUSINESS_TYPE_ENUM, BUSINESS_TYPE_ENUM_ARRAY} from "@/models/businessTypeEnum";
import BusinessPDF from "@/components/BusinessPDF.vue";
import CustomHeader from "@/components/tables/CustomHeader.vue";
import CustomBody from "@/components/tables/CustomBody.vue";
library.add(faChevronLeft, faPlus, faChevronRight, faMoneyBillTransfer, faList, faFilePen, faFileLines, faFilePdf);

export default {
  name: 'BusinessTable',
  computed: {
    BUSINESS_STATUS_ENUM() {
      return BUSINESS_STATUS_ENUM
    },
    BUSINESS_TYPE_ENUM() {
      return BUSINESS_TYPE_ENUM
    }
  },
  components: {
    CustomBody,
    CustomHeader,
    BusinessPDF,
    CustomTable,
    EditElementsBusinessModal,
    AddCollectionModal,
    AddBusinessModal,
  },
  data: function () {
    return {
      data: [],
      ct: {},
      formattedDateTime: {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit"
      },
      formattedDate: {
        day: "numeric",
        month: "numeric",
        year: "numeric"
      },
      filtersInputs: {
        field: {label: 'Champs', value: '', type: 'select', selectValues: [
            { label: "Date de création", value: "createdAt" },
            { label: "N°", value: "number" },
            { label: "Titre", value: "title" },
          ]},
        sort: {label: 'Trié par', value: 'number', type: 'select', selectValues: [
            { label: "Date de création", value: "createdAt" },
            { label: "Montant", value: "ttcAmount" },
            { label: "N°", value: "number" },
            { label: "Status", value: "status" },
          ]},
      }
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {
    BUSINESS_TYPE_ENUM_ARRAY() {
      return BUSINESS_TYPE_ENUM_ARRAY
    },
    reloadCallback(){
      window.location.href=`/businesses${this.$refs.table.buildURLParams()}`;
    },
    updateData(sortedData) {
      this.data = sortedData;
    },
    async loadData(){
      this.$refs.table.startLoading();
      this.data = [];
      // set default url params
      if(!this.$route.query.order) {
        this.$refs.table.$refs.searchArray.selfInputs.order.value = "DESCENDING";
      }
      const resp = await BusinessController.getAll(this.$refs.table.buildURLParams());
      if(resp && !resp.errors){
        for(let i=0; i < resp.length ;i++){
          this.data.push(resp[i]);
        }
      }
      this.$refs.table?.stopLoading();
    },
    determinateColor(amount){
      if(amount < 0){
        return "alert"
      }else if(amount === 0){
        return "classic"
      }else{
        return "warning"
      }
    },
    displayPrimeTTC(primeTTC){
      return primeTTC ? primeTTC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "-";
    },
    displayRest(business){
      return business.primeTTC && business.collected
          ? (business.primeTTC - business.collected).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "-";
    },
    async downloadPdf(business){
      this.$refs.table.startLoading();
      await this.$refs.businessPDF.print(business);
      this.$refs.table?.stopLoading();
    }
  }
}
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  .alert
    color #dc0000
    font-weight bold
  .classic
    color auto
  .warning
    color #d58900
    font-weight bold

  .fa-file-pdf
    margin-top -2px
    height 27px !important
    width 27px !important

  .button-icon
    margin-right 10px
</style>
