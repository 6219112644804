<template>
  <CustomForm ref="form" :inputs=inputs :submit=buttons.submit style="max-width: 400px; margin: auto;">
    <CustomTextField :content=inputs.name />
    <CustomTextField :content=inputs.color />
    <div class="options-container">
      <div class="line">
        <span class="line1"></span>
        <p class="title">Options</p>
        <span class="line2"></span>
      </div>
      <div v-if="options && options.length > 0">
        <div class="option-container" v-for="(option, index) in options" :key="index">
          <p class="option-name">{{option}}</p>
        </div>
      </div>
    </div>
    <CustomButton style="margin-top: 30px" @click="submitForm" :params=buttons.submit />
  </CustomForm>
</template>

<script>
    import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
    import router from "@/router";
    import {NotificationService} from "@vues/v-common";
    import {GroupController} from "@/controllers/user/GroupController";
    import CustomForm from "@/components/forms/CustomForm.vue";
    import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";

    export default {
        name: 'GroupParametersForm',
        components: {
          CustomButton,
          CustomForm,
          CustomTextField,
        },
        data: function () {
            return {
                options: [],
                inputs: {
                  name: {label: 'Nom', value: '', readOnly: false, type: 'text'},
                  color: {label: 'Couleur', value: '', readOnly: false, type: 'text'},
                },
                buttons:{
                  submit: {text: "Mettre à jour"},
                },
                error: false,
                message: ""
            }
        },
        async mounted(){
            this.$refs.form.startLoading();
            const resp = await GroupController.get(localStorage.groupId)
            if(!resp.errors){
              this.inputs.name.value = resp.name;
              this.inputs.color.value = resp.color;
              this.options = resp.options;
            }
            this.$refs.form?.stopLoading();
        },
      methods: {
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => GroupController.patch(localStorage.groupId, {
            name: this.inputs.name.value,
            color: this.inputs.color.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success","Groupe modifié");
            router.push("/group-parameters");
          }
        }
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .tva-container
    display flex
    .numeroTVA
      margin-right 1em
    .TVA
      width 5em

  .separator
    margin-top 15px
    height 2px
    background-color THEME_COLOR_PRIMARY_FADDED
    opacity 0.6

  .options-container
    .line
      display flex
      align-items center
      .line1
        width 15px
        height 1px
        background-color #d1d1d4
      .line2
        height 1px
        background-color #d1d1d4
        width 100%
      .title
        color #444
        font-weight 600
        margin 0
        margin-bottom 5px
        margin-top 5px
        padding-left 5px
        padding-right 5px
    .option-container
      border solid 1px
      margin-top 5px
      margin-bottom 5px
      border-color #d1d1d4
      background-color #f8f8f8
      .option-name
        text-align center
        margin 5px
        color #a8a8a8
        font-weight 600
        font-size 15px
</style>
