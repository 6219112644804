<template>
  <div class="transform-container">
    <div class="global-container next-page-transform">
      <div class="desktop-only">
        <div class="design">
          <div class="text">
            <h1 style="font-size: 3.5em !important">Connectez-vous</h1>
            <h1 style="font-size: 2.7em !important">Et digitalisez votre entreprise</h1>
          </div>
        </div>
      </div>
      <div class="choose-group-container">
        <div v-if="!(isJoinGroupSelected || isCreateGroupSelected)" class="choice">
          <h1>Espace entreprise</h1>
          <p>Vous devez être dans un espace entreprise afin de pouvoir accéder
            <br> à l'intégralités des fonctionnalitées de l'application.
            <br/>Il vous faut donc <a @click="joinGroupSelected()">rejoindre</a> ou <a @click="createGroupSelected()">créer</a> un espace entreprise.</p>
          <a>Pour plus d'informations</a>
        </div>
        <div v-if="isJoinGroupSelected || isCreateGroupSelected" class="card-container">
          <button @click="back" class="button-icon-custom button-arrow"><span class="fas fa-arrow-left"></span></button>
          <div v-if="isJoinGroupSelected" class="card back-button-padding">
            <h1><strong>Rejoindre</strong></h1>
            <h1>un espace entreprise</h1>
            <p>Vous souhaitez rejoindre un espace entreprise déjà créé dans le site. il vous suffit de <strong>contacter</strong> l'un des modérateurs ou administrateurs de cet espace entreprise et lui donner le code suivant : </p>
            <div class="clipboard">
              <CustomTextField :content=inputs.userId></CustomTextField>
              <button id="clipboard" class="button-icon-custom button-clipboard">
                <span class="clipboard-tooltip" :class="{ showed : isClipboardTooltip }">Copié !</span>
                <span class="fas fa-paste"></span>
              </button>
            </div>
          </div>
          <div v-if="isCreateGroupSelected" class="card back-button-padding">
            <h1><strong>Créer</strong></h1>
            <h1>un espace entreprise</h1>
            <p>Vous pouvez créer votre espace entreprise simplement en renseignant le nom de celui-ci dans le champs ci-dessous, l'espace sera automatiquement créée et vous y serez ajouter en tant qu'administrateur</p>
            <label v-if="newGroupNameErrorLabel" :class="{ error : this.inputs.newGroupName.error === true, success : this.inputs.newGroupName.error === false }">{{newGroupNameErrorLabel}}</label>
            <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
              <CustomTextField class="default-margin" :content=inputs.newGroupName />
              <CustomButton @click="submitForm" :params=buttons.submit />
            </CustomForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
    import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import {faArrowLeft, faPaste} from "@fortawesome/free-solid-svg-icons";
    import {GroupController} from "@/controllers/user/GroupController";
    import router from "@/router";
    import CustomForm from "@/components/forms/CustomForm.vue";
    import { NotificationService } from "@vues/v-common";
    library.add(faArrowLeft);
    library.add(faPaste);

    export default {
        name: 'ChooseGroupView',
        components: {
          CustomForm,
          CustomButton,
          CustomTextField
        },
        data() {
          return {
            inputs: {
              newGroupName: {label: 'Nom de l\'entreprise', value: '', type: 'text'},
              userId: {label: 'Code', value: 'Un problème est survenue', type: 'text', readOnly: true}
            },
            buttons:{
              submit: {text: "Valider", disabled: true},
            },
            isCreateGroupSelected: false,
            isJoinGroupSelected: false,
            newGroupNameErrorLabel : undefined,
            newGroupNameDebounceTimeout : undefined,
            userId : "Un problème est survenue",
            isClipboardTooltip : false
          };
        },
      mounted() {
        this.inputs.userId.value = localStorage.userId;
      },
      methods: {
        joinGroupSelected(){
          this.isJoinGroupSelected = true;
          const self = this;
          //add event listner to elementtrigger
          setTimeout(() => {
            let trigger = document.getElementById("clipboard");
            trigger.addEventListener("click", function () {
              //create the readonly textarea with the text in it and hide it
              let tarea = document.createElement("textarea");
              tarea.setAttribute("id", "clipboard" + "-copyarea");
              tarea.setAttribute("readonly", "readonly");
              tarea.setAttribute("style", "opacity: 0; position: absolute; z-index: -1; top: 0; left: -9999px;");
              tarea.appendChild(document.createTextNode(self.inputs.userId.value));
              document.body.appendChild(tarea);

              //select and copy the text in the readonly text area
              tarea.select();
              // TODO Regarder ça
              document.execCommand("copy");

              //remove the element from the DOM
              document.body.removeChild(tarea);

              // Call back
              self.isClipboardTooltip = true;
              setTimeout(() => {
                self.isClipboardTooltip = false;
              }, 3000);

            });
          }, 1000);
        },
        createGroupSelected(){
          this.isCreateGroupSelected = true;
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => GroupController.post({
            name : this.inputs.newGroupName.value,
            color : '24396b'
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Espace entreprise créé et rejoint");
            (new NotificationService).addNotification("success", "Veuillez vous reconnectez");
            router.push("/login");
          }
        },
        back(){
          this.isCreateGroupSelected = false;
          this.isJoinGroupSelected = false;
        }
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @import 'dualViews.styl'
  .choice
    h2
      font-weight 500
    a
      font-weight bold
      cursor pointer
      color THEME_COLOR_PRIMARY
  .choose-group-container
    display flex
    height 100%
    width 100%
    align-items center
    justify-content space-around
    .card-container
      min-width 300px
      max-width 400px
      height 500px
      .clickable
        cursor pointer
      .clickable:hover
        background-color #6e6e6e
      .back-button-padding
        margin-top -10px
      .card
        border-radius 15px
        display flex
        flex-direction column
        padding-left 40px
        padding-right 40px
        height 100%
        .default-margin
          margin-bottom 20px
        label
          &.error
            color #cd384e
          &.success
            color #1595d4
        h1
          padding-bottom 0
          margin 0
          font-size 1.6em !important
          text-align center
          padding-top 0
          strong
            color #313d5a
      .button-arrow
        width 15px
        margin-top 10px
        margin-left 5px
        left 0
        right auto
  .clipboard
    display flex
    .button-clipboard
      margin-top 20px
      margin-left 10px
      .clipboard-tooltip
        position absolute
        visibility hidden
        opacity 0
        margin-top -45px
        margin-left -15px
        box-shadow 0 0 5px 0 rgba(0, 0, 0, 0.3)
        border-radius 5px
        padding 5px
        transition: visibility 0s, opacity 0.3s linear;
        &.showed
          visibility visible
          opacity 1

  .transform-container
    overflow-x hidden
    height 100%
  .next-page-transform
    animation moveLeft 1s forwards

  @keyframes moveLeft
    from
      transform: translateX(100%);
    to
      transform: translateX(0%);
</style>
