import {NotificationService} from "@vues/v-common";
import {RestService} from "@/services/RestService";

export class Controller {

    static manageError(response){
        if(response && response.errors)
            for (let i = 0; i < response.errors.length; i++)
                (new NotificationService).addNotification("danger", response.errors[i].message);
    }

    static handleResponse(response, resolve){
        this.manageError(response);
        resolve(response)
    }

    static requestBuilder(method, request, isAuth = false, body = undefined, headers = {'Content-Type': 'application/json'}){
        return new Promise(function (resolve) {
            ((new RestService()).restRequest(method, `${process.env.VUE_APP_BACK_URL}${request}`, isAuth, body, headers)
                .then( response => Controller.handleResponse(response, resolve)));
        });
    }

}
