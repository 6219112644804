<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Ajout d'un utilisateur</h2>
    <p>Afin d'ajouter un utilisateur à votre espace entreprise il faut demander à celui-ci son jeton.</p>
    <p>Pour obtenir ce jeton l'utilisateur doit confirmer son email, ensuite il lui suffira de se connecter et de cliquer sur rejoindre un espace entreprise.</p>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <CustomTextField :content=inputs.userToken />
        <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import {UserController} from "@/controllers/user/UserController";
import {NotificationService} from "@vues/v-common";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
library.add(faXmark);

  export default {
      name: 'AddUserModal',
      props: ['callback'],
      components: {CustomModal, CustomForm, CustomTextField, CustomButton},
      data() {
          return {
            inputs: {
              userToken: {label: 'Jeton', value: '', type: 'text'}
            },
            buttons:{
              submit: {text: "Ajouter"},
            }
          };
      },
      methods: {
        openModal() {
          this.$refs.modal.openModal();
          this.inputs.userToken.value = '';
        },
        closeModal(event = false) {
          this.$refs.modal?.closeModal(event);
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => UserController.addUserToGroup(
              this.inputs.userToken.value
          ));
          if (!res.errors) {
            (new NotificationService).addNotification("success","Utilisateur ajouté");
            this.closeModal(true);
            this.callback();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .modal
      padding-bottom 40px
      height auto
    p
      padding-left 40px
      padding-right 40px
    .button-container
      display flex
      justify-content center
      padding-top 20px
</style>
