<template>
    <div class="container-screen">
        <div class="screen">
          <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
              <CustomTextField :content=inputs.password />

              <CustomTextField :content=inputs.passwordConfirm />

              <div  class="bad message_field"><span v-if="message">{{message}}</span></div>

              <CustomButton @click="submitForm" :params=buttons.submit />

              <div class="separator">
                <span></span>
                <label>Se connecter ?</label>
                <span></span>
              </div>

              <router-link :to="'/login'">
                <CustomButton :params=buttons.back />
              </router-link>
          </CustomForm>
        </div>
    </div>
</template>

<script>
 import CustomButton from "./customs/customButton/CustomButton.vue";
 import CustomTextField from "./customs/customTextField/CustomTextField.vue";
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faLock, faChevronRight, faAt } from "@fortawesome/free-solid-svg-icons";
 import {NotificationService} from "@vues/v-common";
 import router from "@/router";
 import CustomForm from "@/components/forms/CustomForm.vue";
 import {UserController} from "@/controllers/user/UserController";
 library.add(faAt);
 library.add(faChevronRight);
 library.add(faLock);

  export default {
      name: 'NewPasswordForm',
      components: {
        CustomForm,
          CustomButton,
          CustomTextField,
      },
      data() {
          return {
              inputs: {
                  password: {label: 'Mot de passe', value: '', type: 'password'},
                  passwordConfirm: {label: 'Confirmation', value: '', type: 'password'}
              },
              buttons:{
                submit: {text: "Valider"},
                back: {text: "Retour"},
              },
              message: '',
          };
      },
      methods: {
        async submitForm() {
          this.buttons.submit.disabled = true;

          //TODO Faire une vérif sur la complexité du mot de passe

          // Check if the two password are identical
          if(this.inputs.password.value !== this.inputs.passwordConfirm.value){
            this.message = 'Les mots de passe ne correspondent pas'
            this.inputs.passwordConfirm.error = true;
            this.buttons.submit.disabled = false;
            return -1;
          }

          const res = await this.$refs.form.submitForm(() => UserController.resetPassword(
              this.$route.params.token,
              this.inputs.password.value
          ));
          if (!res.errors) {
            router.push("/login");
            (new NotificationService).addNotification("success","Votre mot de passe a été changé, vous pouvez vous connecter.");
          }
        },
      }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl'
  @import 'responsive-form.styl'
</style>