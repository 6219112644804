<template>
  <div class="global-container" :class="{ 'next-page-transform' : isNextPage }">
    <div class="form">
      <h1>J'ai besoin de votre accord</h1>
      <p>Veillez prendre connaissance et valider nos politiques de condition général d'utilisation et de vente ainsi que de gestion des cookies</p>
      <div class="text-container">
        <div class="line">
          <div v-show="!isCGUAccepted"><span class="fas fa-square-minus"></span></div>
          <div v-show="isCGUAccepted"><span class="fas fa-square-check"></span></div>
          <p>Condition Général d'Utilisation</p></div>
        <div class="line" :class="{deactivated : !isCGUAccepted}">
          <div v-show="!isCookiesAccepted"><span class="fas fa-square-minus"></span></div>
          <div v-show="isCookiesAccepted"><span class="fas fa-square-check"></span></div>
          <p>Politique de gestion des cookies</p></div>
      </div>
    </div>
    <div class="arrow-mobile"><span class="fas fa-circle-arrow-down"></span></div>
    <div class="arrow-desktop"><span class="fas fa-circle-arrow-left"></span></div>
    <div class="cgu">
      <div v-if="!isCGUAccepted">
        <CGUContent/>
        <div class="button-container"><CustomButton @click="validateCGU" :params=buttons.submitCGU /></div>
      </div>
      <div v-if="isCGUAccepted">
        <CookiesContent @workCookieUpdated="workCookieUpdated"/>
        <div class="button-container"><CustomButton @click="validateCookies" :params=buttons.submitCookies /></div>
      </div>
    </div>
  </div>
</template>

<script>
    import CGUContent from "@/components/CGUContent.vue";
    import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSquareMinus, faSquareCheck, faCircleArrowDown, faCircleArrowLeft} from "@fortawesome/free-solid-svg-icons";
    import router from "@/router";
    import CookiesContent from "@/components/CookiesContent.vue";
    import {UserController} from "@/controllers/user/UserController";
    import {NotificationService} from "@vues/v-common";
    library.add(faSquareMinus);
    library.add(faSquareCheck);
    library.add(faCircleArrowDown);
    library.add(faCircleArrowLeft);

    export default {
        name: 'AcceptCGUView',
        components: {CookiesContent, CustomButton, CGUContent},
        data() {
          return {
            isCGUAccepted: false,
            isCookiesAccepted: false,
            cookies: { workCookie: true, analyticsCookie : true},
            isNextPage: false,
            buttons:{
              submitCGU: {text: "Valider"},
              submitCookies: {text: "Valider", disabled : false},
            },
          };
        },
        methods: {
            workCookieUpdated(val){
              this.buttons.submitCookies.disabled = val;
            },
            validateCGU(){
              this.isCGUAccepted = true;
            },
            async validateCookies(){
              if(this.cookies.workCookie) {
                this.isCookiesAccepted = true;
                const resp = await UserController.patch(localStorage.userId,{
                  isCGUAccepted: this.isCGUAccepted,
                  isWorkCookiesAccepted: this.cookies.workCookie,
                  isAnalyticCookiesAccepted: this.cookies.analyticsCookie
                });
                if(!resp.errors){
                  (new NotificationService).addNotification("success","Conditions acceptées");
                  // Wait 1 second
                  setTimeout(() => {
                    this.isNextPage = true;
                    setTimeout(() => router.push("/choose-group"), 500);
                  }, 500)
                }
              }
            }
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .global-container
    display flex
    flex-direction column
    //div
    //  width 100%

  .cgu
    .button-container
      display flex
      justify-content center
      padding-top 3rem
      padding-bottom 3em

  .arrow-desktop
    display none
  .arrow-mobile
    width 100%
    display flex
    justify-content center
    svg
      margin-top 1em
      height 2.5em

  .form
    display flex
    flex-direction column
    width 100%
    h1
      text-align center
    .text-container
      width calc(100% - 6rem)
      padding-right 3rem
      padding-left 3rem
      .line
        display flex
        align-items center
        div
          width 2em
          svg
            height 1.5em
            width 1.5em

  .hide
    display none

  .fa-square-check
    color green

  .deactivated
    div svg
      color grey
    p
      color grey

  .next-page-transform
    animation: moveLeft 1s forwards;

  @keyframes moveLeft
    from
      transform: translateX(0);
    to
      transform: translateX(-100%);

  @media only screen and (min-width: DESKTOP_SIZE)
    .global-container
      flex-direction row-reverse

    .cgu
      /*height calc(1vh - NAV_HEIGHT - FOOTER_HEIGHT)*/
      width calc(60% - 1em)
      height 82vh
      overflow-y scroll
      padding-left 1em

    .form
      width calc(40% - 6rem)
      margin-top 10%
      padding-right 3em
      padding-left 3em


    .arrow-mobile
      display none
    .arrow-desktop
      width 2.5em
      height 82vh
      display flex
      align-items center
      svg
        margin-top 1em
        height 2.5em

</style>