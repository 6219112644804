<template>
  <div class="global-container">
    <!-- Drag and drop -->
    <input style="display: none" type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept=".xlsx" />

    <div v-if="!this.file" class="drag-and-drop-container">
      <div class="dragleave" style="height: 200px; margin: 20px; padding: 20px" @dragover="dragover" @dragleave="dragleave" @drop="drop" >
        <div class="container" style="pointer-events: none;height: 100%;" id="container">
          <label class="line2" style="text-align: center;font-weight: bold;">Déplacer un fichier ici pour téléverser une attestation</label>
          <label class="line3" style="text-align: center;font-weight: bold;">OU</label>
          <div class="line4" style="display: flex; justify-content: center;"><button class="button-icon" id="button" style="pointer-events: auto; width: 130px" @click="this.$refs.file.click()">Cliquez ici</button></div>
        </div>
        <div class="dragover-hide arrow" id="arrow" style="pointer-events: none"><i class="fa-solid fa-up-long"></i></div>
      </div>
    </div>

    <div v-show="this.file" style="width: 100%">
      <CustomTable ref="table" :filters="undefined">
        <template v-slot:table>
          <thead>
          <tr>
            <th>N° Quittance</th>
            <th>Prime Totale</th>
          </tr>
          </thead>
          <tbody v-if="data && data.length > 0">
          <tr v-for="(business, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index">
            <td>{{business.quittance ? business.quittance : '-'}}</td>
            <td>{{business.primeTotale ? business.primeTotale : '-'}}</td>
          </tr>
          </tbody>
          <tbody v-else><tr class="empty-row"><td colspan="12">Aucun contrat enregitré</td></tr></tbody>
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script>
    import {NotificationService} from "@vues/v-common";
    import {InsuranceBusinessController} from "@/controllers/insurance/InsuranceBusinessController";
    import CustomTable from "@/components/CustomTable.vue";

    export default {
      name: 'InsuranceRecoveryCompanyPart',
      components: {CustomTable},
      data() {
        return {
          documentName: "test",
          file: undefined,
          isTerminateButtonDisplayed: false,
          data: []
        };
      },
      methods:{
        async submitExcel(file){
          this.$refs.table.$refs.loader.startLoading();
          // Set the pdf in a form data
          let data = new FormData();
          data.append('pdf', file);
          // Post
          const resp = await InsuranceBusinessController.extractCompanyRecovery(data)
          if (!resp.errors){
            (new NotificationService).addNotification("success","Fichier de recouvrement traité : " + file.name);
            console.log(resp);
            this.data = resp;
            this.$refs.table.$refs.loader.stopLoading();
          }else{
            if(resp.errors) {
              this.file = undefined;
              (new NotificationService).addNotification("warning","Erreur sur le traitement du fichier : " + file.name);
            }
          }
        },
        /* DRAG AND DROP SECTION */
        onChange() {
          this.file = undefined;
          const selectedFiles = [...this.$refs.file.files];
          if (selectedFiles.length > 1) {
            (new NotificationService).addNotification("warning", "Qu'un seul fichier est accepté");
          }else{
            if(selectedFiles[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
              // Set the status that can be used later for know if the file is successfully send or not
              selectedFiles[0].status = '';
              this.file = selectedFiles[0];
              this.submitExcel(selectedFiles[0]);
            }else{
              // Warn when file ot a pdf
              (new NotificationService).addNotification("warning", "Le document doit être un excel : " + selectedFiles[0].name);
            }
          }
        },
        dragover(event) {
          event.preventDefault();

          // Change area style
          event.currentTarget.classList.add('dragover');
          event.currentTarget.classList.remove('dragleave');

          // Hide text
          document.getElementById("container").classList.add("dragover-hide");
          document.getElementById("container").classList.remove("dragover-show");

          // Hide button
          document.getElementById("button").classList.add("dragover-hide");
          document.getElementById("button").classList.remove("dragover-show");

          // Show arrow
          document.getElementById("arrow").classList.add("dragover-show");
          document.getElementById("arrow").classList.remove("dragover-hide");
        },
        dragleave(event) {

          // Change area style
          event.currentTarget.classList.add('dragleave');
          event.currentTarget.classList.remove('dragover');
          event.currentTarget.classList.remove('dragover-hide');

          // Show text
          document.getElementById("container").classList.add("dragover-show");
          document.getElementById("container").classList.remove("dragover-hide");

          // Show button
          document.getElementById("button").classList.add("dragover-show");
          document.getElementById("button").classList.remove("dragover-hide");

          // Hide arrow
          document.getElementById("arrow").classList.add("dragover-hide");
          document.getElementById("arrow").classList.remove("dragover-show");
        },
        drop(event) {
          event.preventDefault();
          this.$refs.file.files = event.dataTransfer.files;
          this.onChange(); // Trigger the onChange event manually
          if(!this.file){
            this.dragleave(event);
          }
        },
        /* DRAG AND DROP SECTION */
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .global-container
    width 100%
    height 100%
    display flex
    justify-content center

  .drag-and-drop-container
    display flex
    align-items center
    justify-content center
    width 100%
    height 100%

  .container
    display grid
    grid-template-columns 1fr
    grid-template-rows repeat(5, 1fr)
    grid-column-gap 0
    grid-row-gap 0

  .line1 { grid-area: 1 / 1 / 2 / 2 }
  .line2 { grid-area: 2 / 1 / 3 / 2 }
  .line3 { grid-area: 3 / 1 / 4 / 2 }
  .line4 { grid-area: 4 / 1 / 5 / 2 }
  .line5 { grid-area: 5 / 1 / 6 / 2 }

  :deep(.loader)
    position relative

  :deep(#paginationArray)
    display none

  // DRAG AND DROP
  .arrow
    position relative
    top -200px
    height 100%
    display flex
    justify-content center
    align-items center
    pointer-events none
    .fa-up-long
      width 50%
      height 50%
      color #002100

  [v-cloak]
    display none

  .dragleave
    background-color #e7e7e7
    border dashed #002100

  .dragover
    background-color #f5f5f5
    border dashed #002100

  .dragover-hide
    opacity 0
    pointer-events none !important

  .dragover-show
    opacity 1
    pointer-events auto
  // DRAG AND DROP
</style>
