<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Créer un devis</h2>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <div class="state-container">
          <CustomSelectField :content=inputs.type />
          <div class="separator-state-container"></div>
          <CustomSelectField ref="statusSelect" :content=inputs.status />
        </div>
        <div class="separator"></div>
        <CustomSelectField style="margin-top: 30px" :content=inputs.client />
        <CustomTextAreaField :content=inputs.addressPro style="height: 150px" />
        <div class="title-number-container">
          <CustomTextField class="title" :content=inputs.title />
          <CustomTextField class="number" :content=inputs.number />
        </div>
        <div class="separator"></div>
        <CustomSelectField style="margin-top: 30px" :content=inputs.delay />
        <CustomTextField :content=inputs.tvaNumber />
        <CustomTextAreaField :content=inputs.bank  style="height: 100px"/>
        <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px"/>
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faPlus, faUpLong, faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
import {ClientController} from "@/controllers/business/ClientController";
import {BUSINESS_STATUS_ENUM, BUSINESS_STATUS_ENUM_ARRAY} from "@/models/businessStatusEnum";
import {NotificationService} from "@vues/v-common";
import {BusinessController} from "@/controllers/business/BusinessController";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import {BUSINESS_TYPE_ENUM} from "@/models/businessTypeEnum";
import {possibleBusinessStatusOnCreation} from "@/utils";
import CustomTextAreaField from "@/components/forms/customs/customTextAreaField/CustomTextAreaField.vue";
import {BusinessGroupParamService} from "@/services/BusinessGroupParamService";
library.add(faXmark);
library.add(faPlus);
library.add(faCheck);
library.add(faUpLong);
library.add(faExclamation);

  export default {
      name: 'AddBusinessModal',
      props: ['callback'],
      components: {CustomTextAreaField, CustomModal, CustomForm, CustomSelectField, CustomTextField, CustomButton},
      data() {
          return {
            documentName: "test",
            inputs: {
              title: {label: 'Titre', value: '', type: 'text', required: true},
              number: {label: 'N°', value: 0, type: 'number', required: true},
              addressPro: {label: 'Address pro', value: '', required: true},
              tvaNumber: {label: 'Numéro de TVA', value: '', type: 'text'},
              bank: {label: 'Coordonnées bancaires', value: '', required: true},
              status: {label: 'Status', value: '', type: 'select', selectValues: [...BUSINESS_STATUS_ENUM_ARRAY], required: true, readOnly: true},
              type: {label: 'Type', value: '', type: 'select', selectValues: [BUSINESS_TYPE_ENUM.QUOTE], required: true},
              delay: {label: 'Délai de paiement', value: '', type: 'select', selectValues: [{label:"45", value: 45}, {label:"30", value: 30}, {label:"15", value: 15}], required: true},
              client: {label: 'Client', value: '', type: 'select', selectValues: [{label:"TODO", value: "TODO"}], required: true},
            },
            buttons:{
              submit: {text: "Suivant"}
            },
            isTerminateButtonDisplayed: false
          };
      },
      watch: {
        "inputs.type.value"(newVal) {
            this.inputs.status.selectValues = possibleBusinessStatusOnCreation(newVal);
            this.$refs.statusSelect.selectThis('');
            this.inputs.status.readOnly = false;
        },
      },
      methods: {
        async openModal() {
          this.$refs.modal.openModal();
          this.$refs.form.startLoading();
          this.buttons.submit.disabled = false;
          this.inputs.title.value = '';
          this.inputs.number.value = 0;
          this.inputs.addressPro.value = '';
          this.inputs.tvaNumber.value = '';
          this.inputs.bank.value = '';
          this.inputs.status.value = BUSINESS_STATUS_ENUM.CREATION.value;
          this.inputs.type.value = BUSINESS_TYPE_ENUM.QUOTE.value;
          this.inputs.delay.value = '';
          this.inputs.client.value = '';
          await this.fillClientSelect();
          await this.fillDefaultFileds();
          this.$refs.form?.stopLoading();
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        async fillDefaultFileds(){
          const resp = await BusinessGroupParamService.get();
          if(!resp.errors){
            this.inputs.addressPro.value = resp.defaultAddressPro;
            this.inputs.tvaNumber.value = resp.defaultTvaNumber;
            this.inputs.bank.value = resp.defaultBank;
            this.inputs.delay.value = resp.defaultDelay;
          }
        },
        async fillClientSelect(){
          // Get the table data
          this.inputs.client.selectValues = [];
          const resp = await ClientController.getAll();
          if(!resp.errors){
            for(let i=0; i < resp.length ;i++){
              this.inputs.client.selectValues.push({label:resp[i].name, value: resp[i].id});
            }
          }
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => BusinessController.post({
            clientId : this.inputs.client.value,
            number : this.inputs.number.value,
            title : this.inputs.title.value,
            addressPro : this.inputs.addressPro.value,
            tvaNumber : this.inputs.tvaNumber.value,
            delay : this.inputs.delay.value,
            bank : this.inputs.bank.value,
            status : this.inputs.status.value,
            type : this.inputs.type.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Contrat ajouté");
            this.terminate();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .title-number-container
      margin-top 15px
      display flex
      .title
        margin-right 1em
      .number
        width 100px !important

    .separator
      margin-top 15px
      height 2px
      background-color THEME_COLOR_PRIMARY_FADDED
      opacity 0.6

    .state-container
      display flex
      margin-top 30px
      .separator-state-container
        width 30px
</style>
