<template>
  <div class="global-container">
    <div class="pageA4" v-if="business" ref="page">
      <!-- <div class="header"><img v-if="data.quotePicture" :src="sourceQuotePicture(data)"></div>-->
      <div class="address-container">
        <p v-html="business.addressPro ? business.addressPro.replaceAll('\n', '<br>') : '-'"></p>
        <p v-html="clientAddress"></p>
      </div>
      <p class="number">Devis N° {{ business.number }}</p>
      <h3 class="title" v-bind:style="{ color: color }">{{business.title}}</h3>
      <div class="info">
        <p class="creationDate">Date d'émission : {{getEmissionDate(business.invoiceSwitchDate)}}</p>
        <p class="delay">Période de validité : {{ business.delay }} jour{{ business.delay === 0 ? "" : "s" }}</p>
      </div>
      <table style="margin-top: 10px">
        <thead>
          <tr v-bind:style="{ 'background-color': color }" class="head-line">
            <th class="description">Description</th>
            <th>Temps/QTÉ</th>
            <th>Prix</th>
            <th>Remise</th>
            <th>TVA</th>
            <th>Montant</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(el, index) in business.elements" :key="index" class="line">
            <td v-if="el.type === 'title'" class="element-title" colspan="5">{{el.text}}</td>
            <td v-if="el.type === 'element'" class="description">{{el.text}}</td>
            <td v-if="el.type === 'element'">{{el.durationQuantity}}</td>
            <td v-if="el.type === 'element'">{{el.unitPrice + (el.unitPrice !== undefined && el.unitPrice !== '' ? " €" : "")}}</td>
            <td v-if="el.type === 'element'">{{el.discountedRate + (el.discountedRate !== undefined && el.discountedRate !== '' ? " %" : "")}}</td>
            <td v-if="el.type === 'element'">{{el.tva !== undefined && el.tva !== '' ? el.tva + " %" : ""}}</td>
            <td v-if="el.type === 'element'" class="element-amount">{{Number(el.amount).toFixed(2) + (el.amount !== undefined && el.amount !== '' ? " €" : "")}}</td>
          </tr>
        </tbody>
      </table>

      <div class="footer-container">
        <div><hr v-bind:style="{ color: color }" style="margin-bottom: 5mm; margin-top: 18mm"></div>
        <div class="content">
          <div class="bank-container" v-html="business.bank ? business.bank.replaceAll('\n', '<br>') : '-'"></div>
          <div class="total-container">
            <div>
              <h5 style="width: 40mm">Total HT</h5>
              <h5 style="width: 20mm;display: flex;justify-content: flex-end">{{business.htAmount ? business.htAmount.toFixed(2) : 0}} €</h5>
            </div>
            <div>
              <h5 style="width: 40mm">Total TVA</h5>
              <h5 style="width: 20mm;display: flex;justify-content: flex-end;">{{business.tvaAmount ? business.tvaAmount.toFixed(2) : 0}} €</h5>
            </div>
            <div>
              <h4 style="width: 40mm" v-bind:style="{ color: color }">Total TTC</h4>
              <h4 style="width: 20mm;display: flex;justify-content: flex-end" v-bind:style="{ color: color }">{{business.ttcAmount ? business.ttcAmount.toFixed(2) : 0}} €</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ClientController} from "@/controllers/business/ClientController";
  import html2pdf from 'html2pdf.js';

  export default {
    name: 'BusinessPDF',
    data: function () {
      return {
        business: undefined,
        clientAddress: undefined,
        color: "#3d5383"
      }
    },
    methods: {
      getEmissionDate(date){
        if(date){
          date = new Date(date);
          return("0" + date.getDate()).slice(-2) + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
        }else{
          let date = new Date();
          return("0" + date.getDate()).slice(-2) + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
        }
      },
      async getClientAddress(business){
        if(business.clientAddress){
          // Invoice case
          return business.clientName + '<br>' + business.clientAddress;
        }else{
          // Quote case, we need to retrieve the address directly on client
          const resp = await ClientController.get(business.clientId);
          if(!resp.errors){
            return (resp.name ? resp.name.replaceAll('\n', '<br>') : "")
                + '<br>'
                + (resp.address ? resp.address.replaceAll('\n', '<br>') : "");
          }else{
            return "";
          }
        }
      },
      async print(business){
        this.business = business;
        this.color = localStorage.color;
        this.clientAddress = await this.getClientAddress(business);

        html2pdf()
            .from(this.$refs.page)
            .set({
              filename: business.number + '_' + (business.title ? business.title.replaceAll(' ','_').replaceAll('<>:|?*\\.','')+'.pdf' : ""),
              margin: 0.3, //margin for pages
              image: { type: 'jpeg', quality: 1.0 },
              html2canvas: { scale : 3, logging: true, dpi: 192, letterRendering: true },
              pagebreak: { avoid: ['.line', '.footer-container'] },
              jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
            })
            .save();

      }
    }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl';

  .global-container
    display none

  .pageA4
    box-shadow 0 0 30px #000000
    width 18.5cm // 21cm
    min-height 27.1cm //29.7 but 27.3 is the max to have a one page PDF
    padding 10mm

  p
    font-size 11pt

  .header
    height 25mm
    img
      height 25mm
      padding-left 5mm

  .address-container
    display flex
    height 30mm
    margin-top 5px
    p
      width 100%

  .number
    height 8mm
    margin-top 10mm

  .title
    height 8mm
    margin-top 2mm

  .info
    height 8mm
    margin-top 2mm
    display flex

  .creationDate
    width 50%

  .delay
    width 50%

  table
    th
      padding: 2px 5px;
  .line
    height 1.5em
    td
      vertical-align bottom
      &.description
        max-width 0
        word-wrap break-word
        white-space normal
      &.element-amount
        text-align right
  .head-line
    height 2em
    th
      width 2mm
      &.description
        width 100%

  .element-title
    font-weight 600

  .total-container
    display flex
    flex-direction column
    margin-top -10px
    div
      display flex
      justify-content flex-end
      h4, h5
        margin 0
        margin-top 10px
        white-space nowrap
      h4
        font-weight 700
      h5
        font-weight 600

  .footer-container
    padding-top 5px
    .content
      padding-top 30px
      display flex
      height 30mm
      div
        width 100%
</style>
